.root {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: calc(80vh - 40px);
	min-height: 644px;
	max-height: 1032px;
	padding-bottom: 20px;
	background-color: #ffffff;
	overflow: hidden;
	@media (min-width: 920px) {
		justify-content: center;
		height: 100vh;
		min-height: 720px;
	}
}

.image {
	position: absolute;

	max-width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
	object-position: top right;
	@media (min-width: 720px) {
		width: 1920px;
		max-width: none;
		object-position: center;
		height: 100%;
	}
}

.description {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	gap: var(--gaps-20, 20px);
	position: relative;
	@media (min-width: 920px) {
		align-items: stretch;
		padding: 96px 0 54px;
		gap: 32px;
		min-height: 560px;
	}
}

.titleWrap {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: var(--gaps-8, 8px);
	align-self: stretch;

	@media (min-width: 920px) {
		align-items: stretch;
		gap: var(--gaps-16, 16px);
	}
}

.title {
	position: relative;
	margin: 0;
	padding-bottom: 12px;
	font-size: 38px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0.01em;
	color: #ffffff;
	text-align: left;
	text-transform: uppercase;
	text-align: center;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 173px;
		height: 2px;
		background: #ffffff;
	}

	@media (min-width: 920px) {
		padding-bottom: 12px;

		// max-width: 900px;
		font-size: 88px;
		font-weight: 700;
		line-height: 64px;
		letter-spacing: 0.01em;
		color: #ffffff;
		text-align: left;
		&:after {
			left: 0;
			transform: translateX(0);
			width: 170px;
			height: 4px;
		}
	}
}

.text {
	color: #ffffff;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: 0.16px;
	text-transform: uppercase;
	@media (min-width: 920px) {
		font-weight: 500;
		font-size: 28px;
		line-height: 48px;
	}
}

.buttonWrap {
	margin-top: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;

	@media (min-width: 920px) {
		margin-top: 24px;
		flex-direction: row;
		gap: 24px;

		button {
			max-width: none;
			width: auto !important;
		}
	}
}

.buttonText {
	color: #ffffff;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	text-align: left;

	@media (max-width: 920px) {
		font-size: 16px;
		text-transform: uppercase;
		line-height: 24px;
		letter-spacing: 0.01em;
		text-align: center;
	}
}
