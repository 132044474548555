.button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	width: 100%;
	font-family: "Tactic Sans", sans-serif, Helvetica;
	transition: background-color 0.3s, color 0.3s, transform 0.3s;
	cursor: pointer;
	border: none;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	@media (min-width: 992px) {
		max-width: 340px;
		padding: 14px 32px;
		font-size: 13px;
	}
}

.button + .button {
	margin-top: 10px;
}

.icon {
	margin-left: 16px;
}

.medium {
	font-weight: 700;
	font-size: 14px;
	line-height: 160%;
	@media screen and (min-width: 992px){
		font-size: 12px;
	}
}

.large {
	padding: 16px 20px;
	font-size: 21px;
	line-height: 26px;
}

.primary {
	color: #FFFFFF;
	background-color: #00658C;
	border: 1px solid transparent;
}

.primary:hover {
	background: #EBF7FF;
	border: 1px solid #00658C;
	color: #00658C;
}

.secondary {
	color: #FFFFFF;
	background: transparent;
	border: 1px solid #FFFFFF;
	&:hover {
		color: var(--dark-blue);
		background: #ffffff;
		border-color: var(--dark-blue);
	}
}

.secondary.dark {
	color: var(--color-text);
	border: 1px solid var(--color-text);
	&:hover {
		color: #FFFFFF;
		background: var(--dark-blue);
	}
}

.secondary:disabled, .primary:disabled {
	color: #ffffff;
	border-color: #c0c0c0;
	background-color: #c0c0c0;
	cursor: default;
}

.more {
	color: var(--color-text);
	background-color: #FFFFFF;
	border: 1px solid #FFFFFF;
}

@media (min-width: 520px) {
	.button {
		width: auto;
	}
}

.link {
	padding: 0;
	background-color: transparent;
	border: none;
	width: max-content;

	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--color-white);
	text-decoration: underline;
	text-transform: none;

	@media (min-width: 992px) {
		padding: 0 5px;
		font-size: 24px;
		line-height: 32px;
		transition: transform 0.2s linear;

		&:hover {
			transform: scale(1.05);
		}
	}
}

.chat {
	font-family: var(--chat-font-family);
	color: #ffffff;
	background: var(--primary-color);
	margin: 0;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.3px;
	text-transform: none;
	width: auto;
	font-size: 17px;
	border-radius: 30px;
	padding: 17px 50px;
	@media (min-width: 992px) {
	}
}


.chat:hover {
	background: var(--dark-blue);
}

.bot {
	width: auto;
	flex-grow: 0;
	padding: 8px 16px;
	border-radius: 6px;
	font-weight: 300;
	font-family: var(--chat-font-family);
	font-size: 13px;
	line-height: 14px;
	text-transform: none;
	background: var(--primary-color);
	color: #FFFFFF;
	letter-spacing: 0;
	@media (min-width: 992px) {
		padding: 12px 16px;
	}
}
