@import "./fonts.css";

:root {
	--color-primary: #001e2d;
	--color-secondary: #26b7bc;
	--color-text: #191c1e;
	--middle-peach: #e3b69f;
	--white-peach: #f2f2f2;
	--second-menu-color: #fff4ed;
	--omoda-blue: #00658c;
	--color-black: #03070a;
	--color-white: #ffffff;
	--color-grey: #e0e0e0;
	--light-orange: #ffe5cc;
	--color-orange: #f5cca6;
	--light-grey: #f5f5f5;
	--color-blue: #0066b5;
	--light-blue: #d3f2f2;
	--dark-grey: #7a7d80;
	--dark-blue: #0a457e;
	--overlay: #00000066;

	--image-size-m: 36px;
	--image-size-d: 60px;
	--gap: 24px;
	--margin-left-m: calc(var(--image-size-m) + var(--gap));
	--margin-left-d: calc(var(--image-size-d) + var(--gap));
	--chat-font-family: "Noto Sans", sans-serif, Helvetica;
	--chat-page-background: #d6e1e7;
	--chat-gifts-background: #30363a;
	--chat-modal-header-background: #1d1d1d;
	--primary-color: #1d3e7e;
	--chat-text-color: #343434;
	--benefit-color: #697279;
	--chat-benefit-background: #db0e27;
	--chat-modal-background: #efeef7;
	--title-color: #262626;
	--bot-message: #ff7900;
}

html,
body {
	padding: 0;
	margin: 0;
}

body {
	font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
	Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	color: #202020;
	max-width: 1920px;
	margin: 0 auto;
}

* {
	box-sizing: border-box;
}

a {
	color: #0A0909;
	text-decoration: none;
	transition: color 0.3s ease;
}

a:hover {
	color: #CA171E;
}

* {
	box-sizing: border-box;
}

p,
ol,
ul {
	font-size: 14px;
	line-height: 150%;
	margin: 0.75em 0;
	padding-left: 0;
}

ul {
	list-style-type: none;
}

ol li {
	margin-bottom: 0.5em;
}

h2 {
	font-size: 20px;
	margin: 0 0 0.75em;
}

h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	margin: 1.25em 0 0;
}

input:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0 50px #fff !important;
	-webkit-text-fill-color: #33333d !important;
}
