.root {
	display: flex;
	align-items: center;
	flex-direction: column;
	@media (min-width: 552px) {
		flex-direction: row;
	}
}

.image {
	flex-shrink: 0;
	width: 100%;
	max-width: 160px;
}

.divider {
	display: none;
	width: 1px;
	height: 48px;
	margin-left: 15px;
	margin-right: 15px;
	background-color: #CDD0D2;
	@media (min-width: 552px) {
		display: block;
		margin-left: 20px;
		margin-right: 20px;
	}
}

.text{
	margin: 0.25em 0 0.5em;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--benefit-color);
	text-align: center;
	@media (min-width: 992px) {
		text-align: left;
	}
}
