.photos {
	height: fit-content;
	width: 100%;
	@media (min-width: 920px) {
		display: grid;
		grid-gap: 16px;
		grid-template-columns: calc(100% - 190px) 174px;
		direction: rtl;
	}
}

.mainPhoto {
	position: relative;

}

.mainPhoto :global .keen-slider__slide {
	flex-shrink: 0;
}

.slideImage {
	display: block;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 400px;
	object-position: center;
	object-fit: cover;

	@media (min-width: 920px) {
		width: 100%;
		max-height: 540px;

		border-radius: 12px;
	}

	@media (min-width: 1600px) {
		width: 100%;
		max-height: 680px;
		object-position: center -200px;

		border-radius: 12px;
	}
}

.otherImages {
	display: none;
	position: relative;
	margin: var(--gaps-xs) 0;
	height: 100%;
	@media (min-width: 920px) {
		display: block;
	}
}

.otherImage {
	cursor: pointer;
	position: relative;
	@media (min-width: 920px) {
		height: calc(540px / 4 - 48px);
	}
	@media (min-width: 1600px) {
		height: calc(680px / 5 - 40px);
	}
	width: 100%;
	overflow: hidden;
	border-radius: var(--radius-xs, 8px);

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

//
//.otherImages :global .keen-slider__slide {
//	max-width: 111px;
//	flex-shrink: 0;
//}
