.benefit{
	text-align: center;
	background: var(--chat-benefit-background);
	color: #fff;
	width: 100%;
	letter-spacing: 0;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;
	padding: 7px 30px;
	cursor: pointer;
	span{
		text-decoration: underline;
	}
}
