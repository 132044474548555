.root {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 640px;
	margin-top: 20px;
	padding: 0 0 60px 0;
	background-color: rgba(250, 250, 250, 0.95);
	border-radius: 28px 28px 0 0;
	font-family: var(--chat-font-family);

	@media (min-width: 992px) {
		width: 830px;
		margin: 20px auto 0;
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 10px;

	@media (min-width: 992px) {
		padding: 0 24px;
	}
}

.colorImage {
	border-radius: 16px;
	border: 1px solid var(--color-grey);
	max-width: 560px;
	margin-left: var(--margin-left-m);

	@media (min-width: 992px) {
		margin-left: var(--margin-left-d);
	}
}

.messages {
	font-family: var(--chat-font-family) !important;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	margin-top: 24px;

	@media (min-width: 992px) {
		margin-top: 54px;
	}

	.sticky {
		position: sticky;
		min-width: 100%;
		border-radius: 0;
		margin: 0;
		padding: 16px;
		top: 65px;
		left: 0;
		z-index: 2;

		@media (min-width: 992px) {
			top: 0;
			padding: 16px 32px;
		}
	}

	.notification {
		display: flex;
		gap: 8px;
		margin-left: var(--margin-left-m);

		@media (min-width: 992px) {
			margin-left: var(--margin-left-d);
		}
	}
}

.messageWrapper {
	position: relative;
	display: flex;
	gap: var(--gap);
}

.botText {
	color: var(--bot-message);
}

.image {
	position: absolute;
	transform: translateX(-64px);
	top: 0;
	height: var(--image-size-m);
	min-width: var(--image-size-m);
	border-radius: 50%;
	overflow: hidden;

	img {
		border-radius: 50%;
		object-fit: contain;
		height: var(--image-size-m);
		width: var(--image-size-m);
	}

	@media (min-width: 992px) {
		transform: translateX(-96px);
		width: var(--image-size-d);
		height: var(--image-size-d);
		img {
			width: var(--image-size-d);
			height: var(--image-size-d);
		}
	}
}

.models {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	max-width: 570px;
	margin-left: var(--margin-left-m);

	@media (min-width: 560px) {
		margin-left: var(--margin-left-d);
	}
}

.complectations {
	display: flex;
	flex-direction: column;
	padding: 4px 0 0 4px;
}

.colors {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	max-width: 580px;
	margin-left: var(--margin-left-m);
	perspective: 500px;

	@media (min-width: 992px) {
		margin-left: var(--margin-left-d);
	}
}

.button {
	font-family: var(--chat-font-family);
	margin-left: var(--margin-left-m);
	border: none;
	color: var(--txt-black);
	font-size: 15px;
	line-height: 18px;

	background: var(--color-white);
	width: max-content;
	cursor: pointer;
	display: inline-block;
	padding: 12px 20px 13px;
	text-align: center;
	transition: all 200ms ease;
	user-select: none;
	box-shadow: 0 1px 8px rgba(38, 36, 60, 0.05);
	border-radius: 50px;
	margin-bottom: 10px;

	&Active {
		color: var(--color-white);
		background: var(--primary-color);
	}

	@media (min-width: 992px) {
		margin-left: var(--margin-left-d);
		transition: background-color 0.2s linear;

		&:hover {
			color: var(--color-white);
			background: var(--primary-color);
		}
	}
}

.loading {
	margin: 0 0 0 var(--margin-left-m);
	width: max-content;
	clip-path: inset(0 1ch 0 0);
	animation: l 1s steps(4) infinite;

	font-size: 14px;
	line-height: 18px;
	color: var(--dark-grey);
	font-style: italic;

	@media (min-width: 992px) {
		margin-left: var(--margin-left-d);
	}
}

@keyframes l {
	to {
		clip-path: inset(0 -1ch 0 0);
	}
}
