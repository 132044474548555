.root {
	max-width: 120px;
	@media (min-width: 920px) {
		max-width: 243px;
	}

	svg {
		width: 100%;
		height: auto;
	}
}
