.form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-left: var(--margin-left-m);
	max-width: 560px;

	@media (min-width: 992px) {
		margin-left: var(--margin-left-d);
	}
}

.message {
	padding: 16px;

	font-size: 16px;
	line-height: 24px;

	border: 1px solid var(--color-grey);
	border-radius: 0 20px 20px 20px;
	background-color: var(--color-white);
}

.input {
	padding: 17px 20px;
	color: #333;
	font-size: 16px;
	line-height: 1;
	max-width: 300px;
	transition: all 200ms ease;
	letter-spacing: 0.3px;
	margin: 0;
	border-radius: 34px;
	border: 1px solid #c7c7c7;
}

.policy {
	font-size: 14px;
	max-width: 300px;
	color: var(--benefit-color);
}

.policyLink {
	text-decoration: underline;
}

.button {
	font-family: var(--chat-font-family);
	padding: 13px 50px;
	border-radius: 48px;
	border: none;

	color: var(--color-white);
	font-size: 17px;
	line-height: 24px;

	background: var(--primary-color);

	max-width: max-content;
	cursor: pointer;
}

.checkboxTitle {
	font-size: 14px;
	margin: 0 0 8px;
}

.checkboxGroup {
	display: flex;
	flex-direction: column;
}

.checkboxContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 8px;
	width: fit-content;
	transition: all 0.3s ease;
}

.checkboxArea {
	display: flex;
	align-items: center;
	gap: 10px;

	svg {
		width: 18px;
		height: 18px;
	}
}

.checkboxText {
	font-size: 18px;
	margin: 0;
}
