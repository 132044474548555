.footer {
	padding: 90px 0;
	background: #33393C;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
	color: #FFFFFF;
}

.logo{
	margin-bottom: 35px;
}

.copyright {
	border-top: 1px solid #41484D;
	padding-top: 35px;

	font-weight: 400;
	font-size: 14px;
	line-height: 140%;


	color: #F0F1F3;
}


.privacy{
	display: block;
	margin-top: 12px;
	font-size: 16px;
	line-height: 140%;
	font-weight: 400;
	color: #F0F1F3;
	text-decoration: underline;
}


