.root {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	visibility: hidden;
	width: 48px;
	height: 48px;
	background: rgba(89, 89, 91, 0.24);
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border-radius: var(--gaps-2, 2px);
	opacity: 0.7;

	svg {
		flex-shrink: 0;
		width: 32px;
		height: 32px;
	}

	@media screen and (min-width: 920px) {
		visibility: visible;
	}

	&:hover {
		opacity: 1;
	}

	&:disabled {
		display: none;
	}
}

.prev {
	left: 1em;

	svg {
		transform: scale(-1);
	}
}

.next {
	right: 1em;
}
