/* noto-sans-300 - latin_cyrillic */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-300.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-regular - latin_cyrillic */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-500 - latin_cyrillic */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-500.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin_cyrillic */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/NotoSans/noto-sans-v27-latin_cyrillic-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}



.rouble {
	font-family: 'Rouble Sans', sans-serif;
}

@font-face {
	font-family: 'Rouble Sans';
	src: url('../fonts/RoubleSans/RoubleSans.woff') format('woff'),
	url('../fonts/RoubleSans/RoubleSans.ttf') format('truetype'),
	url('../fonts/RoubleSans/RoubleSans.eot') format('opentype');
	font-weight: normal;
	font-style: normal;
}
