.root {
	padding: 24px 0 16px;
	@media (min-width: 920px) {
		padding: 56px 0 24px;
	}
}

.list {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: var(--gaps-12, 12px);
	align-self: stretch;
	margin: 0;
	@media (min-width: 920px) {
		gap: var(--gaps-32, 32px);
	}
}

.divider {
	width: 1px;
	height: 66px;
	background: var(--colors-disabled-16-from-0-a-0-a-0-a, #D8D8D8);
	@media (min-width: 920px) {
		height: 70px;
	}
}

.item {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	flex: 1 0 0;
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	text-align: center;
	gap: var(--gaps-4, 4px);
	font-size: 14px;
	line-height: 20px;
	@media (min-width: 920px) {
		gap: var(--gaps-8, 8px);
		font-size: 20px;
		line-height: 28px;
	}

}

.row {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	gap: var(--gaps-2, 2px);
	width: 100%;
	@media (min-width: 920px) {
		justify-content: flex-start;
		gap: var(--gaps-8, 8px);
	}
}

.number {
	color: var(--element-colors-el-brand-1, var(--colors-5, #6DBCE7));

	font-size: 39px;
	font-style: normal;
	font-weight: 800;
	line-height: 28px;
	letter-spacing: 0.56px;
	text-transform: uppercase;
	@media (min-width: 920px) {
		font-size: 56px;
		line-height: 40px;
	}
}

.text {
	display: flex;
	height: 100%;
	align-items: flex-end;
	color: #FFFFFF;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	@media (min-width: 920px) {
		font-size: 20px;
		line-height: 24px;
	}
}
