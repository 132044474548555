.root {
	flex-grow: 1;
	width: 100%;
	overflow: auto;
	padding-top: var(--gaps-12, 12px);
	margin: 0 -16px;
	@media (min-width: 920px) {
		padding-top: 0;
		flex-grow: 0;
		max-width: max-content;
		order: 2;

	}
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: var(--gaps-24, 24px);
	padding: 0 16px;

	min-width: max-content;
	@media (min-width: 920px) {
		gap: var(--gaps-48, 48px);
	}
}

.link {
	cursor: pointer;
	color: #202020;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	min-width: max-content;

	@media (min-width: 920px) {
		font-size: 16px;
		line-height: 24px;
		&:hover {
			color: #2EA6E7;
		}
	}
}
