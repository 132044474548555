.contacts {
	padding-top: 20px;

	@media (min-width: 920px) {
		padding-top: 0;
	}
}

.title {
	margin-bottom: 24px;
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	text-align: center;

	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px; /* 116.667% */
	letter-spacing: 0.24px;
	text-transform: uppercase;

	@media (min-width: 920px) {
		margin-bottom: 40px;

		text-align: left;

		font-size: 48px;
		line-height: 56px;
	}
}

.mapInfo {
	display: flex;
	flex-direction: column-reverse;
	@media (min-width: 920px) {
		flex-direction: row;
		gap: var(--gaps-m);
		padding: var(--gaps-s) 0 var(--gaps-xl);
	}
}

.infoItem {
	background-color: #FFFFFF;
	padding: 16px;
	@media (min-width: 920px) {
		flex-grow: 1;
		padding: 56px 32px;
	}
}

.itemInner {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	padding: 8px 0;
	@media (min-width: 920px) {
		padding: 16px 0;
		font-size: 20px;
		line-height: 28px;
	}
}

.description {
	margin-bottom: var(--gaps-xxs);
	font-weight: 700;
}

.content {
	display: flex;
	align-items: center;
	gap: 8px;
	word-wrap: anywhere;

	svg {
		width: 24px;
		height: 24px;
	}
}

.mapWrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 50vh;
	@media (min-width: 920px) {
		width: 50%;
		height: 500px;
		flex-shrink: 0;
	}
}

.map {
	height: 50vh;
	border-radius: var(--radius-s);
	overflow: hidden;
	@media (min-width: 920px) {
		height: 500px;
	}
}

.buttonWrap {
	padding: 24px 0;
}
