.root {
	@media (min-width: 920px) {
		padding: 40px 0;
	}
}

.wrapper {
	padding: 20px 0;
	@media (min-width: 920px) {
		display: flex;
		gap: 24px;
		align-items: center;
	}
}

.title {
	margin-bottom: 8px;
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	text-align: center;

	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px;
	letter-spacing: 0.24px;
	text-transform: uppercase;
	@media (min-width: 920px) {
		font-size: 48px;
		line-height: 56px;
		letter-spacing: 0.48px;
		text-align: left;
	}
}

.description {
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	text-align: center;

	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	@media (min-width: 920px) {
		font-size: 32px;
		line-height: 48px;
		text-align: left;
	}
}

.info {
	flex-grow: 1;
}

.features {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 24px 0 32px;
	@media (min-width: 920px) {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 32px;
		padding: 40px 0 0;
	}
	@media (min-width: 1600px) {
		gap: 40px 32px;
		padding: 56px 0 0;
	}
}

.feature {
	display: flex;
	align-items: center;
	gap: var(--gaps-12, 12px);
	@media (min-width: 920px) {
		width: 100%;
		max-width: calc(50% - 32px);
	}
}

.icon {
	display: flex;
	width: 42px;
	height: 42px;
	justify-content: center;
	align-items: center;
	@media (min-width: 920px) {
		flex-shrink: 0;
		width: 54px;
		height: 54px;
	}
}

.text {
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	@media (min-width: 920px) {
		font-size: 16px;
		line-height: 24px;
	}
	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 28px;
	}
}

.slider {
	flex-shrink: 0;
	width: 100%;
	@media (min-width: 920px) {
		max-width: 50%;
	}
	@media (min-width: 1600px) {
		max-width: 720px;
	}
}

.image {
	position: relative;

	img {
		display: block;
		border-radius: 8px;
		width: 100%;
		height: auto;
		object-fit: contain;
	}
}

.dots {
	position: absolute;
	inset-inline-end: 0;
	bottom: 12px;
	inset-inline-start: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-inline-start: 0;
	list-style: none;
}

.dot {
	position: relative;
	display: block;
	width: 24px;
	height: 3px;
	padding: 0;
	color: transparent;
	font-size: 0;
	background: #ffffff;
	border: 0;
	border-radius: 2px;
	outline: none;
	cursor: pointer;
	opacity: 0.3;
	transition: all 0.3s;
	margin: 0 4px;
}

.dot:focus {
	outline: none;
}

.dot.active {
	width: 48px;
	background: #ffffff;
	opacity: 1;
}
