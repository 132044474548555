.root {
	top: 0;
	left: 0;
	width: 100%;

	background-color: #FFFFFF;
	z-index: 100;

	@media (min-width: 992px) {
		position: relative;
	}
}

.container{
	max-width: 1312px;
	margin: 0 auto;
	padding: 0 28px;
	@media (min-width: 992px) {
		padding: 0 20px;
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: var(--overlay);
	z-index: 99;
}

.headerModal {
	position: fixed;
	padding: 16px 0 32px;
	top: 65px;
	left: 0;
	height: auto;
	width: 100%;
	background-color: #181b1d;
	z-index: 100;
	border-radius: 0 0 28px 28px;
	box-shadow: 0 10px 10px 0 grey;
}

.modalLinks {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.headerWrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4px 0 8px;

	@media (min-width: 992px) {
		flex-direction: initial;
		justify-content: space-between;
		align-items: center;
		padding: 16px 0;
	}
}

.contactsWrapper{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	@media (min-width: 992px) {
		margin: 0;
		width: fit-content;
		flex-direction: initial;
		gap: 26px;
	}
	button{
		@media (max-width: 992px) {
			width: 100%;
		}
	}
}

.contacts{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.contactsItem {
	display: flex;
	align-items: center;
	padding: 0;
	font-size: 12px;
	line-height: 20px;
	color: #3C4143;
	text-align: center;
	font-weight: 400;

	@media (min-width: 992px) {
		text-align: right;
	}

	svg {
		margin-right: 5px;
	}
}

.phone {
	display: flex;
	width: 100%;

	text-align: right;

	@media (min-width: 992px) {
		width: fit-content;
	}
}

.tel {
	font-size: 20px;
	line-height: 24px;
	margin: 0 auto;
	font-weight: 400;

	text-align: right;

}

.address {
	display: flex;
	font-size: 12px;
	line-height: 14px;
	margin-top: 8px;
	color: #767C82;
	@media (min-width: 992px) {
		font-size: 16px;
		line-height: 17px;
	}

}

.button {
	padding: 0 0 0 5px;
	margin: 0;
	height: 25px;
	background-color: transparent;
	border: none;

	@media (min-width: 992px) {
		display: none;
	}
}
