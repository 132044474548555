.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: transparent;
	pointer-events: none;
	z-index: 3000;
	opacity: 0;
	color: #33333d;
	padding: 0;
	transition: visibility 0s linear .2s,opacity .2s ease;
	@media (min-width: 450px) {
		justify-content: center;
	}
}

.modal.active {
	background-color: rgba(0, 0, 17, 0.2);
	pointer-events: auto;
	visibility: visible;
	opacity: 1;
	transition-delay: 0s;
}

.modal.active form {
	opacity: 1;
}

.callback {
	position: relative;
	width: 100%;
	@media (min-width: 450px) {
		max-width: 552px;
		border-radius: 8px;
		overflow: hidden;
	}
}

.callback *:not(input) {
	user-select: none;
}

.callback__form {
	width: 100%;
	background-color: #F7F7F7;
	position: relative;
	margin: auto;
	padding: 40px 16px;
	box-shadow: 20px 27px 70px 0 rgba(0, 0, 0, 0.1);
	@media (min-width: 450px) {
		padding: 40px 32px;
	}
}

.callback__comment {
	color: #555559;
	opacity: 0.8;
	margin: 0 0 24px;
}

.callback__input {
	border-radius: 4px;
	display: block;
	width: 100%;
	margin: 4px 0 12px;
	padding: 0 16px;
	line-height: 47px;
	border: 1px solid var(--colors-2, #B5B5B5);
	font-size: 16px;
	transition: border-color 0.17s ease;
	background: #FFFFFF;
	color: #5A5758;

}

.callback__input:focus {
	border-color: #0A0909;
}

.callback__input.err {
	border-color: #CA171E;
}

.callback__input.success {
	border-color: #39b54a;
}

@media only screen and (max-width: 430px) {
	.callback__form {
		max-height: 100%;
		max-width: 100%;
		height: 100%;
		padding-top: 60px;
	}

}

.close {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
}

.close:hover {
	transform: scale(0.9);
	opacity: 0.5;
}

.image {
	max-width: 100%;

	img {
		display: block;
		width: 100%;
		height: 240px;
		object-fit: cover;
		object-position: center;
	}
}

.title {
	margin-bottom: 24px;
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 116.667% */
	letter-spacing: 0.24px;
	text-transform: uppercase;
}

.policy {
	color: var(--txt-colors-txt-light-secondary, var(--colors-4, #59595B));
	margin-top: 16px;

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

}

.link {
	text-decoration: underline;
}

.buttonWrap {
	margin-top: 16px;
}

.error {
	box-shadow: inset 0 0 0 2px #faa;
	background-color: #ffeeee
}

.text {
	padding-bottom: 30px;
}
