.root {
	display: flex;
	align-items: center;
	gap: 10px;
	text-align: center;
	transition: all 200ms ease;
	cursor: pointer;
	user-select: none;
	background: #ffffff;
	box-shadow: 0 1px 8px rgba(38, 36, 60, 0.05);
	border-radius: 50px;
	color: #333333;
	margin-bottom: 10px;
	padding: 8px 15px 10px;
	font-size: 14px;
	line-height: 25px;
	@media (min-width: 992px) {
		padding: 13px 30px;
		font-size: 16px;

		&:hover {
			background: var(--primary-color);
			color: #fff;
		}
	}
}

.color {
	width: 24px;
	height: 24px;
	border-radius: 5px;
	flex-shrink: 0;
	border: 1px solid #80808038;
}

.active {
	background: var(--primary-color);
	color: #fff;
}
