.root {
}

.title {
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	@media (min-width: 920px) {
		text-align: left;
		font-size: 32px;
		line-height: 48px;
	}
}

.list {
	display: flex;
	justify-content: flex-start;
	gap: var(--gaps-12, 12px);
	margin-top: var(--gaps-12, 12px);
	margin-bottom: 4px;
	@media (min-width: 920px) {
		margin-top: var(--gaps-32, 32px);
		gap: var(--gaps-48, 48px);
	}
}

.divider {
	width: 1px;
	background: #FFFFFF;
}

.item {
	display: grid;
	grid-template-columns: 52px 40px;
	grid-template-rows: 30px 10px;
	justify-content: center;
	grid-gap: 4px;
	@media (min-width: 920px) {
		justify-content: flex-start;
		grid-template-columns: 31px 100px;
		grid-gap: 12px 16px;
		grid-template-rows: 18px 16px;
		min-width: auto;
	}

}

.number {
	text-align: right;
	font-size: 39px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;

	background: #FFFFFF;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	@media (min-width: 920px) {
		font-size: 56px;
		line-height: 38px;
		grid-row-start: span 2;
	}
}

.text {
	display: flex;
	height: 100%;
	align-items: flex-end;
	color: #FFFFFF;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	@media (min-width: 920px) {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
	}
}

.secondRow {
	display: flex;
	justify-content: center;
	width: 100%;
	line-height: 10px;
	grid-column-start: span 2;
	@media (min-width: 920px) {
		line-height: 26px;
		justify-content: flex-start;
		grid-column-start: auto;
	}
}
