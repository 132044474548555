.fixedBotInfo {
	position: fixed;
	top: 0;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100vw);
	left: 0;
	z-index: 900;
	box-shadow: 0 0 10px #00000017;
	padding: 10px 5px;
	flex-direction: row;
	gap: 10px;
	@media (min-width: 992px) {
		left: auto;
		width: 830px;
		align-items: center;
		padding: 10px 32px;
	}
}

.botInfo {
	display: flex;
	gap: 16px;
	align-items: center;
	flex-shrink: 0;
}

.image {
	position: relative;
	height: 35px;
	width: 35px;
	flex-shrink: 0;
	img {
		height: 35px;
		width: 35px;
		border-radius: 50%;
		@media (min-width: 992px) {
			height: 60px;
			width: 60px;
		}
	}
	&:after {
		content: "";
		position: absolute;
		transform: translateY(-50%);
		left: 27px;
		width: 9px;
		height: 9px;
		bottom: -3px;
		background-color: #56d456;
		border-radius: 50%;
	}
	@media (min-width: 992px) {
		height: 60px;
		width: 60px;
		&:after {
			bottom: -2px;
			left: 47px;
			width: 10px;
			height: 10px;
		}
	}
}

.botName {
	font-size: 16px;
	line-height: 148%;
	font-weight: 700;
}

.botPost {
	font-size: 12px;
	@media (min-width: 456px) {
		font-size: 13px;
	}
}

.phone {
	display: none;
	@media (min-width: 456px) {
		display: block;
	}
}
