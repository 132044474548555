.root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;

	font-family: var(--chat-font-family);
	letter-spacing: 0;
}

.main {
	position: relative;
	padding: 0;
	color: var(--chat-text-color);

	@media (min-width: 992px) {
		padding: 32px 0 0 0;
	}
}

.container {
	padding: 0 10px;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: url("../../../public/images/bg.webp") no-repeat top right / cover var(--chat-page-background);
	z-index: -1;

	@media (max-width: 560px) {
		background: url("../../../public/images/bg-m.webp") no-repeat top right / cover var(--chat-page-background);
	}
}

.finish {
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	padding: 0 20px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s ease;

	&Active {
		visibility: visible;
		opacity: 1;
	}

	@media (min-width: 992px) {
		height: 80vh;
	}
}
