.root {
	position: relative;
	background-color: #a0a0a0;
	overflow: hidden;
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	min-width: 430px;
	z-index: 1;

	img {
		max-width: 100%;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.wrapper {
	padding: 20px 0;
	position: relative;
	z-index: 2;
	@media (min-width: 920px) {
		padding: 40px 0;
	}
}

.title {
	margin-bottom: 8px;

	color: var(--txt-colors-txt-dark-primary, var(--colors-1, #FFF));
	text-align: center;

	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px; /* 116.667% */
	letter-spacing: 0.24px;
	text-transform: uppercase;

	@media (min-width: 920px) {
		margin-bottom: 16px;

		font-size: 48px;
		line-height: 56px;
		text-align: left;
	}
}

.description {
	color: var(--txt-colors-txt-dark-primary, var(--colors-1, #FFF));
	text-align: center;

	font-size: 16px;
	line-height: 24px; /* 150% */
	@media (min-width: 920px) {
		font-size: 32px;
		line-height: 48px;
		text-align: left;
	}
}

.form {
	margin-top: 24px;
	@media (min-width: 920px) {
		margin-top: 40px;
	}
}

.label {
	margin-bottom: 8px;
	color: var(--txt-colors-txt-dark-primary, var(--colors-1, #FFF));

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	@media (min-width: 920px) {
		margin-bottom: 16px;
		font-size: 20px;
		line-height: 28px;
	}
}

.formWrap {
	display: flex;
	flex-direction: column;
	gap: 8px;
	@media (min-width: 920px) {
		max-width: 788px;
		flex-direction: row;
		gap: 16px;
	}
}

.input {
	display: block;
	width: 100%;
	padding: 12px 16px;

	font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
	Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	border-radius: var(--gaps-4, 4px);
	border: 1px solid var(--element-colors-el-dark-secondary, #B5B5B5);
	background: var(--colors-1, #FFF);

	@media (min-width: 920px) {
		padding: 12px 20px;
		font-size: 20px;
		line-height: 28px;
	}
}

.policy {
	margin-top: 16px;
	color: var(--txt-colors-txt-dark-primary, var(--colors-1, #FFF));

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	@media (min-width: 920px) {
		margin-top: 24px;

		font-size: 20px;
		line-height: 28px;
	}
}

.link {
	color: var(--txt-colors-txt-dark-primary, var(--colors-1, #FFF));
	text-decoration: underline;
}
