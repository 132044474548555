.root {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2px;
}

.color {
	cursor: pointer;
	width: 36px;
	height: 36px;
	flex-shrink: 0;
	border-radius: 4px;
	box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.12);
}
