.button {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 100%;
	border: none;
	padding: 12px 40px;
	transition: background-color 0.5s, fill 0.5s, color 0.5s, transform 0.5s;

	font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
	Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	white-space: nowrap;
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	border-radius: 4px;

	&:disabled {
		background-color: #9aa7ad;
		cursor: no-drop;
		color: #010c13;

		&:hover {
			background: #9aa7ad;
		}
	}

	@media (min-width: 920px) {
		width: auto;

		font-size: 20px;
		padding: 18px 40px;
		transition: all 0.2s ease;
		&:hover {
			filter: brightness(0.94);
		}
	}
}

.primary {
	background: var(--colors-5, #6DBCE7);
}

.main {
	border: none;
	background: #FFFFFF;
	color: #202020;

	@media (min-width: 920px) {
		border: none;
		&:hover {
			background: #c7ebff69;
		}
	}
}

.secondary {
	background: #FFFFFF;
	border: 2px solid var(--element-colors-el-bb, #2EA6E7);
}

.reset {
	padding: 8px 12px;
	border-radius: var(--gaps-16, 16px);
	border: none;
	background-color: var(--colors-8, rgba(10, 10, 10, 0.08));
	@media (min-width: 920px) {
		font-size: 14px;
		line-height: 20px;
	}
}

.param {
	padding: 8px 12px;
	border-radius: var(--gaps-16, 16px);
	border: none;
	color: #FFFFFF;
	background-color: var(--colors-3, #202020);
	@media (min-width: 920px) {
		font-size: 14px;
		line-height: 20px;
	}
}

.sort {
	justify-content: start;
	padding: 0;
	width: 100%;
	background: none;
	font-size: 14px;

	&:hover {
		color: #9aa7ad;
	}

	@media screen and (min-width: 576px) {
		justify-content: end;
		font-size: 16px;
	}
}
