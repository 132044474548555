.gifts{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	padding: 15px 20px;
	background: var(--chat-gifts-background);
	border-radius: 24px 24px 0 0;
	@media (min-width: 768px) {
		padding: 30px 40px;
		flex-direction: initial;
	}
}

.giftItem{
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFFFFF;
	gap: 10px;
	img{
		width: 36px;
		height: 36px;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.text{
		font-size: 13px;
		line-height: 148%;
	}
}
