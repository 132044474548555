.root {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	gap: 12px;
	padding: 20px 0;
	@media (min-width: 920px) {
		padding: 80px 0;
	}
}

.img {
	display: none;
	border-radius: 8px;
	overflow: hidden;

	@media (min-width: 920px) {
		display: block;
		z-index: 1;
		position: absolute;
		left: 0;
	}

	img {
		object-fit: cover;
	}
}

.wrapper {
	position: relative;
	z-index: 2;
	background-color: #FFFFFF;
	width: 100%;
	border-radius: 8px;
	@media (min-width: 920px) {
		max-width: 844px;
		width: 100%;
		margin-left: auto;
		padding: var(--gaps-56, 56px);
		border-radius: var(--gaps-8, 8px);
		background: var(--colors-1, #FFF);

		box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.12);
	}
}

.title {
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	text-align: center;

	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px; /* 116.667% */
	letter-spacing: 0.24px;
	text-transform: uppercase;
	@media (min-width: 920px) {
		font-size: 48px;
		line-height: 56px;
	}
}

.selectModel {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 4px;
	padding: 8px 0 24px;
	@media (min-width: 920px) {
		padding: 8px 0 32px;
	}
}

.model {
	flex-grow: 0;
	flex-shrink: 1;
	text-align: center;
	font-size: 14px;
	line-height: 20px;
	img {
		display: block;
		border-radius: 8px;
		max-width: 100%;
		height: auto;
		border: 1px solid transparent;
		margin-bottom: 4px;
	}
	@media (min-width: 920px) {
		cursor: pointer;
		font-size: 20px;
		line-height: 28px;
		img {
			border-width: 2px;
		}
	}
}

.active {
	img {
		border: 1px solid #fa6941;
		@media (min-width: 920px) {
			border-width: 2px;
		}
	}
}

.row {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
}

.total {
	font-size: 16px;
	line-height: 130%;

	span {
		font-weight: 700;
	}
}

.inputWrap {
	position: relative;
}

.inputWrap span {
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	background-color: white;
	margin-bottom: 4px;
	@media (min-width: 920px) {
		margin-bottom: 8px;
		font-size: 20px;
		line-height: 28px;
	}

}

.inputWrap input {
	width: 100%;
	margin-bottom: 0;
	padding: 4px;
	color: var(--txt-colors-txt-light-secondary, var(--colors-4, #59595B));

	font-family: Noto Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
	Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	display: flex;
	align-items: center;
	letter-spacing: 0.5px;

	background: white;

	border-radius: var(--gaps-4, 4px);
	border: 1px solid rgba(89, 89, 91, 0.40);

	@media (min-width: 920px) {
		padding: 8px 8px 4px;
		font-size: 20px;
		line-height: 28px;
	}
}

.resultWrapper {
	padding-top: 16px;
	@media (min-width: 920px) {
		display: flex;
		align-items: center;
		padding-top: 32px;
		gap: 28px;
	}
}

.result {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0 0 16px 0;
	@media (min-width: 920px) {
		width: auto;
		gap: 8px;
		flex-shrink: 0;
		padding: 0;
	}
}

.resultLabel {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	max-width: 160px;

	@media (min-width: 920px) {
		max-width: none;
		font-size: 20px;
		line-height: 28px;
	}
}

.resultNumber {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;

	@media (min-width: 920px) {
		font-size: 20px;
		line-height: 28px;
	}
}

.disclaimer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	color: rgba(28, 27, 31, 0.32);
	padding-top: 28px;

	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		margin: 4px 0;
		@media (min-width: 920px) {
			font-size: 20px;
			line-height: 28px;
		}
	}
}

.button {
	display: flex;
	justify-content: center;
	padding: 16px 0;
}
