.root {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;
	padding: 16px 0;
	@media (min-width: 920px) {
		gap: 40px;
		margin-bottom: 40px;
		padding: 32px 0;
	}
}

.title {
	color: var(--txt-colors-txt-light-primary, var(--colors-3, #202020));
	text-align: center;

	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px;
	letter-spacing: 0.24px;
	text-transform: uppercase;
	@media (min-width: 920px) {
		font-size: 48px;
		font-style: normal;
		font-weight: 300;
		line-height: 56px;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media (min-width: 920px) {
		gap: 24px;
	}
}

.controls {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	@media (min-width: 920px) {
		gap: 32px;
	}
}

.radio {
	display: flex;
	align-items: center;
	gap: 4px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	transition: color 0.2s ease;

	svg {
		width: 20px;
		height: 20px;
	}

	@media (min-width: 920px) {
		svg {
			width: 32px;
			height: 32px;
		}
		gap: 8px;
		font-size: 20px;
		line-height: 28px;
		&:hover {
			cursor: pointer;
			color: #2EA6E7;
		}
	}
}

.description {
	color: #202020;

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	@media (min-width: 920px) {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
	}
}

.buttonWrap {
	display: flex;
	justify-content: center;
	padding: 8px 0 0;
	@media (min-width: 920px) {
		padding: 16px 0 0;
	}
}
