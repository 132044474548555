.root {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 111;
	width: 100%;
	padding: var(--gaps-12, 12px) 0;
	background-color: rgba(245, 246, 251, 0.64);
	backdrop-filter: blur(10px);

	@media (min-width: 920px) {
		padding: 20px 0;
	}
}

.wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media (min-width: 920px) {
		gap: 80px;
		align-items: center;
	}
}

.phone {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: var(--gaps-4, 4px);
	color: #202020;
	font-size: 14px;
	line-height: 20px;
	@media (min-width: 920px) {
		gap: var(--gaps-8, 8px);
		font-size: 18px;
		line-height: 28px;
		order: 3;
		margin-left: auto;
		svg {
			width: 38px;
			height: 38px;
		}
	}
}
